import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

import * as Forge from 'node-forge';
@Injectable({
    providedIn: 'root'
})
export class EncryptDecryptService {
    formulateKey = Number(String(environment.Z4)+''+String(environment.A0)+''+String(environment.Z3)+''+String(environment.A1)+''+String(environment.Z2));
    private key = CryptoJS.enc.Utf8.parse(this.formulateKey);
    private iv = CryptoJS.enc.Utf8.parse(environment.Eiv);
    publicKey: string = `-----BEGIN PUBLIC KEY-----
    sqK9unsJJH1H3teU2dUaGEpwPWH4CZsFbbnQmvjyIuXXSWwoEoNxT7TX8kc2OEwrhmuGFhNj0IClz6uxCecCfjBcIGZbC1nEuVwFiJEOq/50n12Ru1PBExWQvbeAOAIfEvPHIxzVjGi2dmTwLFPY347pGohThERHK8hmbrU99b0=
    -----END PUBLIC KEY-----`;

    constructor() {}
    // Methods for the encrypt and decrypt Using AES
    encryptUsingAES256(text): any {
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }//end
    // encryptWithPublicKey(valueToEncrypt: string): string {
    //     const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
    //     var someStuff = rsa.publicKey.n.toString(16);
    //     //console.log('some stuff = ',someStuff)
    
    //     return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
    // }//end
    decryptUsingAES256(decString) {
        var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
            keySize: 128,
            blockSize:128,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }//end


}