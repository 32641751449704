<nav class="navbar navbar-expand-md navbar-light fixed-top navbar-top navbar-dark bg-dark">
  <button class="btn mr-2" type="button" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand mr-auto pr-4" routerLink="/home">
    <img src="assets/images/ln-logo.png" alt="" />
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav mr-auto ml-5">
      <li class="nav-item page-title">
        <strong>Court Services > </strong>{{ pageTitle }}
      </li>
    </ul>
    <ul class="navbar-nav mx-auto" *ngIf="sessionTimeRemainingMinutes == 0; else showNormalSessionInfo">
      <li class="nav-item">
        <label>your session timed out!</label>
      </li>
    </ul>
    <ng-template #showNormalSessionInfo>
      <ul class="navbar-nav mx-auto" *ngIf="sessionTimeRemainingMinutes >= sessionTimeOutWarningThreshold; else warnUserAboutSession">
        <li class="nav-item">
          <label>session times out in {{ sessionTimeRemainingMinutes }}:{{ sessionTimeRemainingSecondsVal }}</label>
        </li>
      </ul>
    </ng-template>
    <ng-template #warnUserAboutSession>
      <ul class="navbar-nav mx-auto hotwarning" *ngIf="sessionTimeRemainingMinutes >= 0 && sessionTimeRemainingMinutes < sessionTimeOutWarningThreshold; else showSessionTimedOutInfo">
        <li class="nav-item">
          <label>session times out in {{ sessionTimeRemainingMinutes }}:{{ sessionTimeRemainingSecondsVal }}</label>
        </li>
      </ul>
    </ng-template>
    <ng-template #showSessionTimedOutInfo>
      <ul class="navbar-nav mx-auto hotwarning">
        <li class="nav-item">
          <label>your session timed out!</label>
        </li>
      </ul>
    </ng-template>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item donotshow">
        <label>{{ $time | async | date: 'hh:mm:ss a' }}</label>
      </li>
      <li class="nav-item refresh" (click)="onRefreshPage()">
        <a class="nav-link mt-10"><em class="fa fa-refresh"></em></a>
      </li>
      <li class="nav-item notification">
        <a class="nav-link mt-10"><i class="fa fa-user" aria-hidden="true"></i></a>
      </li>
      <li *ngIf="user" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Hello {{user.name}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#" (click)="logout()">
            Logout
         </a>
        </div>
      </li>
      
    </ul>
  </div>
</nav>
