<app-navbar-main></app-navbar-main>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-2 sidebar-div px-0" *ngIf="isSidenavOpen">
      <app-sidebar-menu></app-sidebar-menu>
    </div>
    <div class="col-md-10 main-cont-top">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
