<nav
class="
  navbar navbar-expand-md navbar-light
  fixed-top
  navbar-top navbar-dark
  bg-dark
"
>
<button class="btn mr-2" type="button">
  <span class="navbar-toggler-icon"></span>
</button>
<a class="navbar-brand mr-auto pr-4">
  <img src="assets/images/ln-logo.png" alt="" />
</a>
<button
  class="navbar-toggler"
  type="button"
  data-toggle="collapse"
  data-target="#collapsibleNavbar"
>
  <span class="navbar-toggler-icon"></span>
</button>
<div class="collapse navbar-collapse" id="collapsibleNavbar">
  <ul class="navbar-nav mr-auto ml-5">
    <li class="nav-item page-title">
      <strong>Court Services > </strong>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <!-- <li class="nav-item refresh">
      <a class="nav-link mt-10"><em class="fa fa-refresh"></em></a>
    </li> -->
    <li class="nav-item notification">
      <!-- <a class="nav-link mt-10"
        ><i class="fa fa-user" aria-hidden="true"></i
      ></a> -->
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      </a>
      <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      </div> -->
    </li>
  </ul>


</div>
</nav>

<br>
<br>
<div class="row loginPage justify-content-center m-0">
    <div class="col-sm-8 col-md-6 col-lg-4" >
        <div class="login-form border rounded bg-white mt-5 p-5">
            <div class="row">

                <div class="col">
                    <div class="logo text-center mb-3">
                        <img src="assets/images/ln-logo-black.png" alt="" />
                    </div>
                    <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <!-- <div class="form-group"><h2>Login</h2></div> -->
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input type="text" formControlName="username" class="form-control" [ngClass]="{'is-invalid': submitted && f.username.errors }"/>
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Username is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input type="password" formControlName="password" class="form-control" [ngClass]="{'is-invalid': submitted && f.password.errors }"/>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                                <div *ngIf="error && !f.password.errors.required" class="invalid-feedback">{{ error }}</div>
                            </div>
                        </div>
                        <div *ngIf="!error" class="form-group login-btn-container">
                            <button [disabled]="loading" class="btn btn-primary login-btn">
                                <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                Login
                            </button>            
                        </div>
                        <div *ngIf="error" class="form-group login-btn-container-error">
                            <div><span class="error">{{error}}</span></div>
                            <button [disabled]="loading" class="btn btn-primary login-btn">
                                <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                Login
                            </button> 
                        </div>
                    </form>
                </div>

            </div>
        </div>

    </div>
</div>