import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './core/shared/data.service';
import { AppService } from './app.service';

//added on 4/21/2022
import { interval, Subscription,Subject } from 'rxjs';
import { AfterViewChecked, DoCheck,OnInit, OnDestroy } from '@angular/core';

//6/9/2022
import {LogData} from 'src/app/models/log-data';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { EncryptDecryptService } from 'src/app/_services/encrypt-decrypt.service';

/*
UPGRADING from angular 10.2 to 14.0
1. runs 4 upgrades
ng update @angular/core@11 @angular/cli@11
ng update @angular/core@12 @angular/cli@12 --force
ng update @angular/core@13 @angular/cli@13 --force
ng update @angular/core@13 @angular/cli@13 --force
ng version

2. 3 installs neeeded
npm i jwt-decode --force
npm i angular-datatables --force
npm i @types/datatables --force

3. change jwt_decode according to new call: example
import jwt_decode from "jwt-decode";
 
var token = "eyJ0eXAiO.../// jwt token";
var decoded = jwt_decode(token);

optional installs
npm i lodash --force
npm i lodash-es --force
npm i chart.js --force
npm i export-to-csv --force
npm i moment --force

*/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,OnDestroy {
  title = 'court-dashboard';
  isLogin = true;
  userInfo:any =  {
    password:'',
    usernameCtrl: '',
    userrollCtrl: ''
  }

  //added on 4/21/2022
  sidebar:Boolean = true;
  source = interval(100);
  initWidth: Number;

  subscription: Subscription;
  logDataAPISubscription: Subscription;
  logOutSubscription: Subscription;
  
  constructor(
    private DataService: DataService,
    private router:Router,
    private appService:AppService,
    private authenticationService: AuthenticationService
                //9/26/2022
                ,private encryptDecryptService: EncryptDecryptService
  )   {
    this.source.subscribe(val => this.refresh());


  }

  refresh(): void {

  }

  ngOnDestroy() : void {
    this.subscription.unsubscribe();
    if(this.logDataAPISubscription && !this.logDataAPISubscription.closed) this.logDataAPISubscription.unsubscribe();    
    if(this.logOutSubscription && !this.logOutSubscription.closed) this.logOutSubscription.unsubscribe();
  }
  
  ngOnInit() {
    console.log('application is initialized');
    //delete old session
    var oldSession = localStorage.getItem("UserInfoSessionOld")||'';
    var currentSession = JSON.parse(localStorage.getItem("UserInfoSession"))||'[]';
    var userinfo = JSON.parse(localStorage.getItem("userInfo"))||'[]';
    var anotherCurrentSession = "";
    if(userinfo && userinfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"]) 
      anotherCurrentSession= userinfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"];

    if(oldSession)
    {
      if(oldSession!==currentSession.session && anotherCurrentSession && anotherCurrentSession==currentSession.session)
      {
        console.log('old user session is logging out...')
        var sessionNow = oldSession;     
        this.logOutSubscription = this.authenticationService.logout(sessionNow).subscribe(stuff=>{
          localStorage.setItem("UserInfoSessionOld", currentSession.session);
        });
      }
      else
      {
        console.log('user session is up-to-date')
      }
    }
  
    this.DataService.getLoginStatus().subscribe(value => {
      this.isLogin = value;


      if(this.isLogin) {
        
        return this.router.navigate(["/analytics"]);       

      }

    })

  }//end


}
