import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../_services/jwt.service';
/*
INTERCEPT HTTP REQUESTS TO APPEND THE BEARER TOKEN
*/
//9/26/2022
import { EncryptDecryptService } from 'src/app/_services/encrypt-decrypt.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private jwtService: JwtService
        //9/26/2022
        ,private encryptDecryptService: EncryptDecryptService
        ) {
         //console.log('interceptor called...');
        }//end of constr

        //added on 9/26/2022
    ExcludeURLList = [
        environment.baseUrl + "log/LogData",
        environment.baseUrl + "user/loginapi",
        environment.baseUrl + "user/logout",
        environment.baseUrl + "Query/RunQuery",
        environment.baseUrl + "Query/SaveMyQuery",
        environment.baseUrl + "Query/SaveSharedQuery",
        environment.baseUrl + "ControlTable",
        environment.baseUrl + "WorkQueue/SaveWorkQueueDataAll",
        environment.baseUrl + "WorkQueue/SaveTempWorkQueueDataAll",

        environment.baseUrl + "Security",
        environment.baseUrl + "SourceSetting/SaveSourceSettingData",
        environment.baseUrl + "PriorityAdmin/UpdatePriority",
    ];

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let token = this.jwtService.getToken();
        if(token)
        {
            // console.log('jwtService role'); console.log(this.jwtService.getTokenInfo().role);
        }
        if (token) 
        {
            //changed on 5/24/2022
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });

            //const clonedRequest = req.clone({ headers: req.headers.append('Authorization', 'Bearer 123') });

        }

        //9/26/2022
        let exludeFound = this.ExcludeURLList.filter(element => {
            return request.url.includes(element)
        });


        if ((exludeFound && exludeFound.length > 0)) 
        {
            if (request.method == "POST") {
                if (request.body || request.body.length > 0) {
                    // let jsonObj:any=JSON.stringify(this.encryptDecryptService.encryptUsingAES256(request.body));
                    // console.log('jsonObj',jsonObj);
                    const cloneReq = request.clone({
                        body: this.encryptDecryptService.encryptUsingAES256(request.body)
                        //body: this.encryptDecryptService.encryptWithPublicKey(request.body)
                    });
                    return next.handle(cloneReq);
                }
                
                let data = request.body as FormData;
                //console.log('else if request.url.indexOf <=0 reached')
                return next.handle(request);
            }

        }

        //console.log('interceptor called...');
        //console.log('excluded url reached')
        return next.handle(request);       
    }
}
