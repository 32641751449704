import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

//added on 4/21/2022
import { DataService } from "src/app/core/shared/data.service";
import { map, catchError, take } from "rxjs/operators";
import { of, observable } from "rxjs";

//6/9/2022
import {LogData} from 'src/app/models/log-data';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: UntypedFormGroup;
  loading: Boolean = false;
  submitted: Boolean = false;
  error: String = '';
  userSubscription: Subscription;
  logDataAPISubscription: Subscription;
  logOutSubscription: Subscription;
  test = '';

  //added on 4/22/2022
  isLogin: boolean;
  userrole = [];

  constructor(private router: Router, 
    private formBuilder: UntypedFormBuilder, 
    private authenticationService: AuthenticationService
    //added on 4/21/2022
    ,private DataService: DataService

    //6/9/2022
    ) {

       //console.log('authentication is in progress...');
       //added on 4/22/2022
       var isLogin = JSON.parse(localStorage.getItem("isLogin"));
       //console.log('login.component constructor() user = ',this.authenticationService.currentUser.value); 

       //bypass login 5/24/2022
      //  isLogin = true;

       //5/17/2022 get roles only if logged in
       if(isLogin && this.authenticationService.currentUser.value)
       DataService.getRole()
       .pipe(catchError(() => of({ responseCode: 401, responseData: [] })))
       .subscribe((data: any) => {
         if (data.responseData.length) {
           this.userrole = data.responseData;
          //  console.log('login.component constructor userrole');console.log(data);
         }
         this.userrole.forEach(e => {
           if(e.roleName == "ReadOnly") {
             e.roleName = 'Read Only'
           }
         });
       });//end

      }//end of

  ngOnInit() {
    //console.log('LOgin');
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.userSubscription = this.authenticationService.currentUser
      .subscribe((user) => {
        // console.log('user');
        // console.log(user);
        /*
        REDIRECT TO HOME SCREEN IF LOGIN IS SUCCESSFUL
        */
        if (user) {

          console.log('USER IS ALREADY LOGGED IN. YAY!');

        };
    });
  }

  ngOnDestroy() {
    if(this.userSubscription && !this.userSubscription.closed) this.userSubscription.unsubscribe();  
    if(this.logDataAPISubscription && !this.logDataAPISubscription.closed) this.logDataAPISubscription.unsubscribe();   
    if(this.logOutSubscription && !this.logOutSubscription.closed) this.logOutSubscription.unsubscribe();  
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    //console.log('Login Submit clicked...');

    this.error = '';
    this.submitted = true;
    if (this.loginForm.invalid) {
      console.log('invalid login info supplied')
      return
    };
    this.loading = true;
    
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        //below was changed from data to userInfo on 4/22/2022
        userinfo => {
          this.loading = false;
          console.log('user authentication is successful')
          //8/25/2022 - normalize username before saving it
          if(userinfo.unique_name)
          {
            userinfo.unique_name = String(userinfo.unique_name).split('@')[0];
          }
          //console.log('login.component  after normalizing userinfo=',userinfo);
          //added on 4/22/2022
          localStorage.setItem("userInfo", JSON.stringify(userinfo));

          let username = "";     
          //assign values

          username = this.loginForm.value.username;
              
          var logData:LogData = {  Type: 'normal',
            Logtext: username +' has successfully logged in!',
            UserName: username};

          this.logDataAPISubscription=this.DataService.logDataAPI(logData).subscribe(data=>{
            //console.log('login.component this.logDataAPISubscription data = ');console.log(data);
                              //changed on 6/16/2022
                              this.DataService.logout(true);
                              //localStorage.setItem("isLogin", "true");
                              // console.log('login.component isLogin=');
                              //also added on 4/22/2022
                              this.DataService.getRoutingsecurity(
                                this.loginForm.value.username
                              ).subscribe((data: any) => {
                                if (data && data.roles) {
                                  localStorage.setItem("menu", JSON.stringify(data.roles));
                                  
            
                                  this.DataService.loginAPI(false);
                                  this.DataService.setnavMenu();
                                  const menuPermission = JSON.parse(localStorage.getItem("menu"));
            
                                  // console.log('login.component authenticationService.login() menuPermission = '); console.log(menuPermission);
            
                                  const RoutePermission = menuPermission.filter(
                                    //(item) => item.roleid == userinfo.responseData.userRoleId
                                    
                                    (item) => item.roleid == 1 //changed on 4/22/2022
                                  );
                                  const pageNavigation = RoutePermission[0].page;
                                  //let userRoleId = 1;
                                  // console.log('userInfo.role = '); console.log(userinfo.role);
                                  if (String(userinfo.role) == 'BISCOURTSERVICES_ADMIN')
                                  //if(userRoleId==1)
                                  {
                                    // console.log('id match 1 login.component pageNavigation[analytics] = '); console.log(pageNavigation["analytics"]);
            
                                    if (pageNavigation["analytics"]) {
                                      this.router.navigate(["/analytics"]);
                                    } else {
                                      this.router.navigate(["/home"]);
                                    }
                                  } else {
                                    if (pageNavigation["workQueue"]) {
                                      this.router.navigate(["/work-queue"]);
                                    } else {
                                      this.router.navigate(["/home"]);
                                    }
                                  }
                                }
                              });//end of added on 4/22/2022
                              

          },
          error=>{
                              //changed on 6/16/2022
                              this.DataService.logout(true);
                              //localStorage.setItem("isLogin", "true");
                              // console.log('login.component isLogin=');
                              //also added on 4/22/2022
                              this.DataService.getRoutingsecurity(
                                this.loginForm.value.username
                              ).subscribe((data: any) => {
                                if (data && data.roles) {
                                  localStorage.setItem("menu", JSON.stringify(data.roles));
                                  
            
                                  this.DataService.loginAPI(false);
                                  this.DataService.setnavMenu();
                                  const menuPermission = JSON.parse(localStorage.getItem("menu"));
            
                                  // console.log('login.component authenticationService.login() menuPermission = '); console.log(menuPermission);
            
                                  const RoutePermission = menuPermission.filter(
                                    //(item) => item.roleid == userinfo.responseData.userRoleId
                                    
                                    (item) => item.roleid == 1 //changed on 4/22/2022
                                  );
                                  const pageNavigation = RoutePermission[0].page;
                                  //let userRoleId = 1;
                                  // console.log('userInfo.role = '); console.log(userinfo.role);
                                  if (String(userinfo.role) == 'BISCOURTSERVICES_ADMIN')
                                  //if(userRoleId==1)
                                  {
                                    // console.log('id match 1 login.component pageNavigation[analytics] = '); console.log(pageNavigation["analytics"]);
            
                                    if (pageNavigation["analytics"]) {
                                      this.router.navigate(["/analytics"]);
                                    } else {
                                      this.router.navigate(["/home"]);
                                    }
                                  } else {
                                    if (pageNavigation["workQueue"]) {
                                      this.router.navigate(["/work-queue"]);
                                    } else {
                                      this.router.navigate(["/home"]);
                                    }
                                  }
                                }
                              });//end of added on 4/22/2022
                              


          });

          //delay 5 seconds before running roles access requests to allow data logging
          this.delayProcessing(2000).then(data =>{

          });

        

        },
        error => {
          this.loading = false;
          this.error = error.message || error; 
          //console.log('user authentication failed; error = ',this.error); 
          console.log('user authentication failed '); 
          // console.log('login.component authenticationService.login() user = ',this.f.username.value);

          var logData:LogData = {  Type: 'Authentication-Error',
          Logtext: this.f.username.value +' has failed during login!',
          UserName: 'system_setup'
        };

        this.logDataAPISubscription=this.DataService.logDataAPI(logData).subscribe(data=>{
          //console.log('login.component this.logDataAPISubscription data = ');console.log(data);
        });
          
        }
      );
  }//end
  async delayProcessing(delayAmount: number) {
    return new Promise(resolve => setTimeout(resolve, delayAmount));
  }//end

}
