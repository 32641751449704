import { Constants } from '../app/_config/constants';

export const environment = {
  production: true,
  baseUrl : Constants.baseURL,
  Z4: 5,
  A0: 98,
  Eiv: 4546222350975589,
  Z3: 203,
  A1: 4770,
  Enke: 5000050000,
  Z2: 311229,
  
};
