import { Component, OnInit,ChangeDetectorRef, OnDestroy } from "@angular/core";
// import { Menu } from "src/app/mocks/menu"; 
import { DataService } from "src/app/core/shared/data.service";
// import {securityMock} from 'src/app/mocks/securityMocks'
// import { userRole } from 'src/app/mocks/role';
import { Router} from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
@Component({
  selector: "app-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.css"],
})
export class SidebarMenuComponent implements OnInit,OnDestroy {
  collapsePull: boolean = false;
  collapsePush: boolean = false;

  sub: Subscription[] = [];
  logDataAPISubscription: Subscription;
  
  constructor(private dataService: DataService, private ref: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute) {}

  navMenu: any = [];
  ngOnInit() {
      this.navMenu = this.dataService.getMenu();
      this.dataService.getSidenavInfo().subscribe(() => {
          this.actionMenu()
      });
      this.dataService.getNavMenu().subscribe(() => {
          this.actionMenu()
      });
      this.sub.push(this.router.events.subscribe((val) => {
          this.actionMenu();
      }));
    //   console.log('new code:::sidebar onInit called...')
    //console.log('sidebar-menu.component oninit called ');
    //console.log('sidebar init called ');

  }//end of fx

  ngOnDestroy() {
    if(this.logDataAPISubscription && !this.logDataAPISubscription.closed) this.logDataAPISubscription.unsubscribe();   
  }//end of fx


  actionMenu() {
    //console.log('sidebar-menu.component actionMenu() called...')
      this.navMenu = this.dataService.getMenu();
      const menuPermission = this.dataService.getMenuPermission();
      // console.log('sidebar-menu.component authenticationService.actionMenu() menuPermission = '); console.log(menuPermission);
      // console.log('sidebar-menu.component authenticationService.actionMenu() this.navMenu = '); console.log(this.navMenu);

      const newmenu=[];
      for (let i = 0; i < this.navMenu.length; i++) {
          const item = this.navMenu[i];

          // console.log('sidebar-menu actionMenu item = '); console.log(item);
          if (item.submenu) {
              const adminDisable = item.submenu.filter(menu => ((menuPermission[menu['Context']] == true) || (menuPermission[menu['Context']] == undefined)));
              if (adminDisable.length) {
                // console.log('!!!!!Item Pushed item = ',item);

                  item.submenu = adminDisable;
                  newmenu.push(item);
              }
          } else {

              if ((menuPermission[item['Context']] == true) || (menuPermission[item['Context']] == undefined)) {
                // console.log('!!!!!Item Pushed item = ',item);

                  newmenu.push(item);
              }
          }
      }//end of for loop
      this.navMenu = newmenu;
  }//end of fx

}

