import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '../_models/user';
import { map } from 'rxjs/operators'
import { JwtService } from './jwt.service'
import { HttpClient } from '@angular/common/http';
import { Constants } from '../_config/constants';
//9/26/2022
import { EncryptDecryptService } from 'src/app/_services/encrypt-decrypt.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  public newUserInfoSession: { 
    session: string} = {session:""};

  constructor(
    private http: HttpClient, private jwtService: JwtService
            //9/26/2022
            ,private encryptDecryptService: EncryptDecryptService
    ) {
    if (localStorage["token"]) {
      var userInfo:any = this.jwtService.getTokenInfo();
      //console.log('user token=');
      //console.log(userInfo);
      //var newUser:User = {name:userInfo.name,session:"",token:userInfo.token};
      this.currentUser.next(userInfo);
    }
  }

  login(username: string, password: string): Observable<any> {
    //QUAZ is JSON key for username; 
    //WHEER is JSON key for password;
    //these were changed to avoid using explit keys that are passed down in JSON for login API request
    let newJSONUser: any = {QUAZ: username, WHEER: password};
    console.log('app.service login called');
    let newPayLoad: any = JSON.stringify(newJSONUser);

        return this.http.post<any>(Constants.loginURL, 
          //{QUAZ: username, WHEER: password}
          newPayLoad
          )
        .pipe(map(userJson => {
          
          if (!userJson.token) throw new Error(userJson.message);
          this.jwtService.saveToken(userJson.token);
          var userInfo:any = this.jwtService.getTokenInfo();
          userInfo.token = userJson.token;
          //console.log('user token p2=');
          //console.log(userInfo.token);
          this.currentUser.next(userInfo);

          if(userInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"])
          {
            this.newUserInfoSession.session = userInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"];
          }
          localStorage.setItem("UserInfoSession", JSON.stringify(this.newUserInfoSession));
        //console.log('user token p2=');
        //console.log(userInfo.token);
        this.currentUser.next(userInfo);
        // console.log('AuthenticationService login this.newUserInfoSession = ',this.newUserInfoSession);
        // console.log("authServ  login ",JSON.parse(localStorage.getItem("UserInfoSession")))
          return userInfo;
        }));
  }

  logout(sessionNow:string=""): Observable<any> {
    //console.log('Auth::logout() called... User logout in progress... session',this.currentUser.value);
    let newPayLoad: any = JSON.stringify({session: sessionNow});

    return this.http.post<any>(Constants.logoutURL, 
      //{session: sessionNow}
      newPayLoad
      ).pipe(map(logOutResult => {
      //console.log('logout callback response = ',logOutResult);

    }));


  }//end of logout fx

  test() {
    return this.http.get<any>(Constants.apiTestURL).pipe(map(response => { return response }));
  }//end


}
