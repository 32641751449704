

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
//import { MatTableModule } from '@angular/material/table';
//import { MatTreeModule } from '@angular/material/tree';
//import { MatIconModule } from '@angular/material/icon';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./core/shared/shared.module";
import { SidebarMenuComponent } from "./layout/sidebar-menu/sidebar-menu.component";
import { DesignComponent } from "./layout/design/design.component";
import { CommonModule } from "@angular/common";
import { NavbarMainComponent } from "./layout/navbar-main/navbar-main.component";
import { LoginComponent } from "./layout/login/login.component";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { AppMenuService } from "./app-menu/app-menu.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
//added on 4/28/2022
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/jwt.error.interceptor';
import {  HTTP_INTERCEPTORS } from '@angular/common/http';
//5/10/2022
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
//import { Ng2SmartTableModule } from "ng2-smart-table";
import { NgSelect2Module } from "ng-select2";
import { NgSelectModule } from "@ng-select/ng-select";
//import { Ng2CompleterModule } from "ng2-completer";
import { PagenotfoundComponent } from './components/errors/pagenotfound/pagenotfound.component';

//added on 6/2/2022
import {ErrorHandler} from '@angular/core';
// import {LogService } from './shared/log.service';
//import {LogTestComponent } from './log-test/log-test.component';

// import { LogPublishersService } from "./shared/log-publishers.service";
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';  //newly added on 10/28/2020
//import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';

//11/1/2024
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({ declarations: [
        AppComponent,
        NavbarMainComponent,
        SidebarMenuComponent,
        DesignComponent,
        LoginComponent,
        PagenotfoundComponent,
        //6/2/2022
        //LogTestComponent
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        SharedModule,
        FormsModule,
        BrowserModule,
        NgbModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        // Ng2SmartTableModule,
        NgbPaginationModule,
        NgSelect2Module,
        NgSelectModule,
        //Ng2CompleterModule,
        MatExpansionModule,
        NgxDatatableModule,
        MatSliderModule,
        ProgressbarModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            closeButton: true
        })], providers: [AppMenuService, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, provideHttpClient(withInterceptorsFromDi()),] })
export class AppModule {}

