

<nav class="sidebar-nav">
  <ul id="sidebarnav">

    <ng-container *ngFor="let item of navMenu">
      <ng-container *ngIf="item.submenu else subMenu">
        <li class="sub-menu-drpdown pb-0">
          <a  class="dropdown-toggle" (click)="collapsePull = !collapsePull" [attr.aria-expanded]="collapsePull" >
            <span>{{item.MenuName}}</span>
            </a>
            <ul class="submenu-list" [ngbCollapse]="collapsePull">
              <ng-container *ngFor="let itemsubmenu of item.submenu">
                <li routerLinkActive="active">
                  <a [routerLink]="itemsubmenu.Path">
                    <span>{{itemsubmenu.MenuName}}</span>
                  </a>
                </li>
              </ng-container>

            </ul>
          </li>
        </ng-container>
      <ng-template #subMenu>

        <li routerLinkActive="active">
        <a [routerLink]="item.Path">
          <span>{{item.MenuName}}</span>
        </a>
      </li>


      </ng-template>
    </ng-container>

   
  </ul>
</nav>
