import { Injectable } from '@angular/core';
//import * as jwt_decode from 'jwt-decode'
//import jwt_decode, { JwtPayload } from 'jwt-decode';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class JwtService {
    constructor() { }

    getToken(): String {
        return window.localStorage['token'];
    }

    getTokenInfo() { 
        if (window.localStorage['token']) {
            
           var  token = window.localStorage['token'];
        var decoded = jwt_decode(token);
        // console.log('tk',token)
        // console.log('dctk',decoded)
            return decoded
            
            //jwt_decode(window.localStorage['token'] || '') || null;//jwt_decode(window.localStorage['token']);
        }
    }

    saveToken(token: String) {
        window.localStorage['token'] = token;
    }

    destroyToken() {
        window.localStorage.removeItem('token');
    }
}