import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataService } from "../../core/shared/data.service";
import { AppMenuService } from 'src/app/app-menu/app-menu.service';
@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit {
  isSidenavOpen:any;
  
  constructor(
    private DataService: DataService,
    private appMenuService:AppMenuService
  ) { 
    
  }
  
  ngOnInit() {
    this.DataService.getSidenavInfo().subscribe(value => {
      this.isSidenavOpen = value
    })
  }

}
