import { Component, OnInit } from '@angular/core';

import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { notificationMessage } from "src/app/mocks/nofification.message";

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {

  constructor(
    private router:Router
    ,private toastr: ToastrService,
  ) { 

  }

  ngOnInit(): void {
    //show page not found error and then route to home
    this.toastr.error(
      notificationMessage.PAGE_NOT_FOUND,
      notificationMessage.ERROR
    );  
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 5000);
  }


}
